@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

:root {
  --color1: #daded4;
  --color2: #a3bcb6;
  --color3: #3c403d;
  --color4: #3a81f5;
  --color5: #ffffff;
  --color6: #39603d;
}

* {
  padding: 0%;
  margin: 0%;
  text-decoration: none;
  font-family: "Kanit", sans-serif;
}

button {
  border-width: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: rgb(57, 96, 61);
  background: -moz-linear-gradient(
    0deg,
    rgba(57, 96, 61, 0.5242471988795518) 63%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(57, 96, 61, 0.5242471988795518) 63%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
      0deg,
      rgba(57, 96, 61, 0.5242471988795518) 63%,
      rgba(255, 255, 255, 1) 100%
    )
    fixed;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#39603d",endColorstr="#ffffff",GradientType=1);
}

.standardShadow {
  box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.5);
}

.desktopStandardShadow {
  box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1100px) {
  .desktopStandardShadow {
    box-shadow: 0 0 0 0;
  }
}

header {
  display: flex;
  justify-content: space-between;
  background-color: var(--color4);
  border-radius: 5px;
  padding: 1em;
  margin: 1em;
  color: black;
  box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid black;
  align-items: center;
}

header h1 {
  font-size: 48px;
}

nav a {
  color: black;
  display: flex;
  justify-content: center;
  transition: 0.1s linear;
}

nav a:hover {
  scale: 1.01;
  cursor: pointer;
  color: black;
}

.loader {
  margin-top: 15em;
  display: flex;
  justify-content: center;
}

.homeInfo {
  text-align: center;
  padding: 1em;
  background-color: var(--color1);
  width: fit-content;
  max-width: 80%;
  margin: auto;
  border-radius: 5px;
  border: 2px solid black;
}

.homeInfo > p {
  font-size: 20px;
}

.highlightText {
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
}

.highlightText:hover {
  cursor: pointer;
}

.homeSection {
  margin-top: 3em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.homeSection a {
  border: 2px solid black;
}

.homeInsetBtn {
  border-radius: 5px;
  padding: 0.25em 0.8em;
  margin: 0.5em;
  transition: 0.3s;
  background-color: var(--color4);
}

.homeInsetBtn:hover {
  box-shadow: inset 0 0 0 2.5em var(--color1);
  cursor: pointer;
}

.insetBtn {
  border-radius: 5px;
  padding: 0.25em 0.8em;
  margin: 0.5em;
  transition: 0.3s;
  background-color: var(--color1);
}

.insetBtn:hover {
  box-shadow: inset 0 0 0 2.5em var(--color4);
  cursor: pointer;
}

.insetBtnInverse {
  border-radius: 5px;
  padding: 0.25em 0.8em;
  margin: 0.5em;
  transition: 0.25s;
  border: 1px solid var(--color3);
  color: var(--color5);
  background-color: var(--color3);
}

.insetBtnInverse:hover {
  border: 1px solid var(--color3);
  box-shadow: inset 0 0 0 2.5em var(--color1);
  color: var(--color3);
  cursor: pointer;
}

.homeSection a {
  width: 45%;
  color: black;
  border-radius: 5px;
  height: 100%;
  margin: 1em;
  background-color: var(--color4);
  text-align: center;
}

.homeSection div:hover {
  background-color: var(--color1);
  cursor: pointer;
}

.homeSection div h1 {
  padding: 2em;
  font-size: 35px;
}

.backBtnSection div:hover {
  cursor: pointer;
  color: black;
}

@media (max-width: 750px) {
  .homeSection a {
    width: 90%;
  }

  .homeSection a div h1 {
    font-size: 28px;
  }

  .homeInfo > p,
  .highlightText {
    font-size: 14px;
  }

  .homeInfo > h2 {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  header h1 {
    font-size: 18px;
  }

  .backBtnSection {
    font-size: 10px;
  }

  header a {
    font-size: 10px;
  }

  .homeSection a div h1 {
    font-size: 24px;
  }

  .homeInfo > p,
  .highlightText {
    font-size: 12px;
  }

  .homeInfo > h2 {
    font-size: 16px;
  }
}

.sectionMain {
  margin-top: 3em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.sectionTitleDiv {
  color: var(--color3);
  border-radius: 5px;
  padding: 2em;
  margin: 1em;
  background-color: var(--color1);
  border: 2px solid black;
  text-align: center;
}

/* LOGIN ******************************************************************************************************************* */

.loginSection {
  color: var(--color3);
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 30%;
  justify-content: center;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

label {
  width: 100%;
}

.form-div {
  background-color: var(--color1);
  padding: 1em;
  border-radius: 5px;
}

.form-div h4 {
  font-size: 30px;
}

.form-input {
  width: 90%;
  margin: auto;
  margin-top: 1em;
  padding: 0.5em;
}

.form-textarea {
  height: 5em;
}

.form-input-50 {
  width: 40%;
  margin: auto;
  margin-top: 1em;
  padding: 0.5em;
}

.form-firstLast {
  display: flex;
  width: 100%;
}

.form-submit {
  padding: 0.2em 2em 0.2em 2em;
  font-weight: bold;
  font-size: 16px;
}

.pass-input {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0.5em;
}

#passToggle,
#passToggle2 {
  width: fit-content;
  display: flex;
  align-items: center;
}

#passToggle:hover,
#passToggle2:hover {
  cursor: pointer;
}

.needLoginSection {
  background-color: var(--color4);
  width: fit-content;
  margin: auto;
  padding: 1em;
  margin-top: 15em;
  color: var(--color5);
  border-radius: 5px;
  text-align: center;
}

.needLoginSection a h3 {
  padding: 0.5em;
}

/* PROFILE PAGE !#$%@#^@$%^@#$%@#$%&^@$%^@%#@&%^#%^&#%^&#%^ */

.profileDiv {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.profileDiv .sectionTitleDiv {
  width: 100%;
}

.profileInfoDiv {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1em;
  justify-content: space-between;
}

.changeInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 33%;
  border-radius: 5px;
  padding: 0.5em;
}

.changeInfoDiv button {
  height: 20%;
}

.accountDiv span {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.accountDiv {
  background-color: var(--color4);
  padding: 1em;
  border-radius: 5px;
  width: 60%;
  border: 2px solid black;
}

.accountDiv span:nth-child(even) {
  background-color: var(--color2);
}

.accountForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 1em;
  border: 2px solid black;
  margin-top: 1em;
  border-radius: 5px;
  background-color: var(--color1);
  padding: 0.5em;
}

.accountForm div {
  width: 80%;
  color: var(--color3);
}

.infoBtnDiv {
  display: flex;
  justify-content: center;
}

@media (max-width: 1100px) {
  .profileDiv {
    width: 75%;
  }
}

@media (max-width: 800px) {
  .profileDiv {
    width: 90%;
    margin: auto;
  }
  .changeInfoDiv {
    padding: 0;
  }
}

@media (max-width: 600px) {
  .profileInfoDiv {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .accountDiv {
    width: 100%;
  }
  .changeInfoDiv {
    flex-direction: row;
    height: fit-content;
    padding: 0.5em;
    width: 80%;
    margin-top: 1em;
  }
  .changeInfoDiv button {
    font-size: 12px;
    padding: 0.2em;
  }
  .accountDiv span {
    font-size: 12px;
    padding: 0.3em;
  }
}

/* PASSWORD RESET PAGE ___________________________________________________AWEOAJSEDFOAWJSEOFASEOFABJWEORTAEKJRTOAEKTOAKWOGTAKWTOKAWOETAGOTKO */

.passResetForm {
  background-color: var(--color1);
  padding: 1em;
  border-radius: 5px;
  max-width: 30%;
  min-width: 250px;
}

.form-resetPW {
  width: 100%;
}

/* FRIEND PAGE ___________________________________________FASASDFAEAWEAW$t5q23ASDFAEFASFAEFASDaj;wlk3j4a;l5kjw;elakJF:SDoiUW:O#4 */

.friendDiv {
  display: flex;
  flex-wrap: wrap;
  width: 45%;
}

.friendDiv .sectionTitleDiv {
  width: 100%;
}

.friendSection {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.addFriendComponent {
  color: var(--color5);
  width: 100%;
  border-radius: 5px;
  border: 2px solid black;
  padding: 1em;
  margin: 1em;
  background-color: var(--color4);
  text-align: center;
}

.friendsListDiv {
  background-color: var(--color4);
  border: 2px solid black;
  width: 100%;
  margin: 1em;
  border-radius: 5px;
}

.friendsListDiv h3 {
  text-align: center;
}

.friendEl {
  display: flex;
  justify-content: space-between;
  background-color: var(--color1);
  margin: 1.5em 0.5em;
  padding: 0.5em 1em 0.2em 1em;
  transition: 0.1s linear;
  border-radius: 5px;
}

.friendRequestEl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color1);
  margin: 1.5em 0.5em;
  padding: 0.5em 1em 0.2em 1em;
  transition: 0.1s linear;
  border-radius: 5px;
}

.friendRequestEl button {
  border: 1px black solid;
}

.friendEl:hover {
  cursor: pointer;
  background-color: var(--color3);
  color: var(--color1);
}

.expandedFriendDiv {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 2px solid black;
  padding: 0.5em;
  margin-top: 1em;
  height: fit-content;
  width: 52%;
  background-color: var(--color4);
}

.expandedFriendDiv p {
  width: 100%;
  text-align: center;
  padding-top: 1em;
}

.expandedFriendDiv h3 {
  text-align: center;
  margin: auto;
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: var(--color3);
  color: var(--color1);
}

.expandedFriendDiv a {
  color: var(--color3);
  width: 100%;
  margin-top: 1em;
  border-radius: 5px;
  padding: 0.5em 1em 0.2em 1em;
  background-color: var(--color1);
  transition: 0.1s linear;
}

.expandedFriendDiv a:hover {
  background-color: var(--color3);
  color: var(--color1);
}

.noFriendSelected {
  margin: auto;
}

.listShown {
  display: none;
}

.removeFriend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  border-radius: 5px;
  margin-top: 4em;
  font-size: 12px;
  padding: 0.3em;
}

.toggleShownFriendList {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.5em;
}

.toggleShownFriendList div {
  width: 40%;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: var(--color1);
  color: var(--color3);
  padding: 0.5em;
  border-radius: 5px;
}

.dropdown-content {
  display: none !important;
  position: absolute;
  background-color: #f9f9f9;
  color: var(--color3);
  left: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content p {
  padding: 0.2em;
  border: 1px solid black;
}

.dropdown-content p:hover {
  cursor: pointer;
  background-color: var(--color3);
  color: var(--color1);
}

.dropdown:hover {
  background-color: var(--color3);
  color: var(--color1);
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block !important;
}

.removeUserFromList:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .friendDiv {
    width: 70%;
    margin: auto;
  }

  .listShown {
    display: block;
  }

  .expandedFriendDiv {
    width: 90%;
    margin: auto;
    text-align: center;
    overflow: hidden;
  }
}

@media (max-width: 800px) {
  .friendEl {
    font-size: 12px;
  }

  .addFriendComponent,
  .friendsListDiv {
    margin: 1em 0 0 0;
  }

  .toggleShownFriendList {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .friendDiv {
    width: 95%;
  }
  .friendEl {
    font-size: 10px;
  }
  .toggleShownFriendList {
    font-size: 10px;
  }
}

/* CREATE LIST **************************************________*_*__*__*_***&_*(_*%(^&_*(_(#%^_&*($_&*($_^&*(_$ */

.createGroupSection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.createListDiv {
  background-color: var(--color1);
  padding: 1em;
  border-radius: 5px;
  width: 25%;
  padding: 2em;
  margin: 1em;
  align-self: flex-start;
}

.createListDiv form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contentDiv {
  background-color: var(--color1);
  text-align: center;
}

/* LISTS )(!)@#(!)@()()()()()(((((((()))))))!@(#)!($)!*%)*^)!*#$)^!*#$%)!*#^$)!*^#)!*^)#*$^)!#*$^)!#*^$ */
.deleteListModal {
  border: black 2px solid;
}

.deleteModalBtnDiv {
  width: 200px;
  text-align: center;
  margin: auto;
}

.deleteModalBtnDiv div {
  margin-top: 2em;
}

.deleteModalText {
  width: 200px;
  text-align: center;
  margin: auto;
  color: var(--color3);
}

.modal-delete-window > div {
  width: 260px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5em;
  border-radius: 5px;
  background: var(--color4);
  box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.5);
}

.listMemberList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-height: 110px;
  overflow-y: auto;
}

.listMember {
  border-radius: 5px;
  transition: 0.1s linear;
  border: 1px solid var(--color3);
  list-style: none;
  margin: 0.3em;
  padding: 0em 0.3em;
  width: 100%;
}

.listMember:hover {
  cursor: pointer;
  background-color: var(--color4);
}

.removeFromListModal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: fit-content;
  background-color: var(--color5);
}

.removeFromListModal button {
  border-radius: 5px;
  padding: 0.2em;
  margin-top: 1em;
  transition: 0.1s linear;
}

.removeFromListModal button:hover {
  cursor: pointer;
  color: var(--color5);
  background-color: var(--color4);
}

.listIcon {
  display: none;
}

.listTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--color4);
  border: 2px black solid;
  border-radius: 5px;
}

.editListBtnDiv {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.fullscreenEditList {
  font-size: 1em;
  margin-left: 1em;
}

.fullscreenEditList:hover {
  cursor: pointer;
  color: var(--color4);
}

.createListComponent {
  color: var(--color5);
  border-radius: 5px;
  padding: 2em;
  margin: 1em;
  background-color: var(--color4);
  text-align: center;
}

.editItem {
  background-color: var(--color4);
  border: 1px black solid;
}
.myListLeft {
  width: 35%;
}

.myListSection {
  display: flex;
  flex-wrap: nowrap;
}

.listDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listDivEl {
  display: flex;
  width: 100%;
}

.listDivEl a {
  background-color: var(--color1);
  color: var(--color3);
  text-align: center;
  width: 80%;
  padding: 1em;
  margin-top: 1em;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid black;
}

.listDivEl a:hover {
  cursor: pointer;
  background-color: var(--color4);
  scale: 1.01;
}

.listDivEl a {
  background-color: var(--color1);
  color: var(--color3);
  padding: 1em;
  border-radius: 5px;
  transition: 0.2s;
}

.reactTrashList {
  background-color: var(--color1);
  color: var(--color3);
  padding: 1em;
  margin: auto;
  border-radius: 5px;
  border: 2px solid black;
  transition: 0.2s;
}

.reactTrash {
  background-color: var(--color1);
  color: var(--color3);
  padding: 1em;
  border: 2px solid black;
  border-radius: 5px;
  transition: 0.2s;
}

.reactTrash:hover,
.reactTrashList:hover {
  cursor: pointer;
  background-color: var(--color3);
  color: var(--color2);
  scale: 1.1;
}

.listComponent div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.listComponent div * {
  width: 100%;
}

.itemMapDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  width: 100%;
}

.itemMapDiv div {
  width: 47%;
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
}

.itemMapDiv .itemSortDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  width: 90%;
  padding: 1em;
  border: 2px solid black;
  border-radius: 5px;
  background-color: var(--color4);
  margin-bottom: 1em;
}

.itemSortDiv h3 {
  width: 100%;
}

.itemMapDiv div a {
  width: 100%;
  background-color: var(--color1);
  border-radius: 5px;
  margin: 1em;
  border: 2px solid black;
  color: var(--color3);
}

.itemMapDiv div a:hover {
  background-color: var(--color4);
}

.itemMapDiv div span {
  align-self: center;
}

.nothingToShow {
  text-align: center;
  width: 100%;
}

.itemDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.2em;
  width: 100%;
}

.itemDiv h2,
.itemDiv p {
  width: 100%;
  text-align: center;
}

.singleItemOptions {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.priorityDiv {
  display: flex;
  width: 100%;
}

.priorityDiv span,
.priorityDivSingleItem span {
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid var(--color4);
  width: 1em;
  padding: 0.1em;
  text-align: center;
}
.priorityDiv span {
  width: 100%;
}

.priorityDivSingleItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 2px solid black;
  border-radius: 5px;
  background-color: var(--color1);
  width: -moz-fit-content;
  text-align: center;
  margin: 0 0.5em;
  justify-content: center;
  padding: 0.3em;
}
.prioActive {
  background-color: var(--color4);
}
.priorityDivSingleItem span {
  margin-left: 0.4em;
}

.priorityDivSingleItem span:hover {
  cursor: pointer;
  background-color: var(--color4);
}

.priorityDivSingleItem p {
  width: 100%;
}

.singleItemTitleDiv {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.singleItemDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.singleItemDetails span {
  width: 100%;
  text-align: center;
}

.itemForm {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
  margin-top: 1em;
  border-radius: 5px;
  background-color: var(--color1);
}

.itemForm div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 95%;
  color: var(--color3);
}

.item-form-input {
  width: 80%;
  margin-top: 1em;
  padding: 0.3em;
}

.item-form-label {
  font-size: 14px;
  margin-top: 1em;
  text-align: center;
  width: fit-content;
}

.myItem {
  width: fit-content;
  max-width: 90%;
  margin: auto;
  height: fit-content;
}

.myItem .reactTrash {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.itemLink {
  color: var(--color4);
  text-decoration: underline;
  font-weight: bold;
}

.purchased {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.purchased input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--color6);
}

.purchased input:checked ~ .checkmark {
  background-color: var(--color6);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.purchased input:checked ~ .checkmark:after {
  display: block;
}

.purchased .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.errorText {
  text-align: center;
  color: red;
}

.successText {
  text-align: center;
  color: var(--color4);
  font-size: 18px;
}

.hidden {
  display: none;
}

.addItemPrio {
  width: 100%;
  background-color: white;
  margin: 1em;
  color: var(--color3);
  font-size: 14px;
}

.addItemPrioOption {
  display: flex;
  justify-content: space-around;
  padding: 0.3em;
}

.addItemPrioOption span {
  border: var(--color3) 1px solid;
  width: 1em;
  border-radius: 5px;
}

.addItemPrioOption span:hover {
  cursor: pointer;
  background-color: var(--color3);
  color: var(--color1);
}

@media (max-width: 1400px) {
  .singleItemTitleDiv h1 {
    font-size: 18px;
    font-weight: 200;
  }

  .itemDiv h2 {
    font-size: 18px;
  }
  .itemDiv h4 {
    font-size: 12px;
    font-weight: 100;
  }
  .sectionTitleDiv p {
    font-size: 14px;
  }
  .sectionTitleDiv {
    padding: 1em;
  }
  .createListComponent {
    padding: 0.5em;
  }
  .createListComponent h2 {
    font-size: 18px;
  }
  .createListComponent form label {
    font-size: 14px;
  }
  .createListComponent form input {
    padding: 0;
  }
}

@media (max-width: 1100px) {
  .fullscreenEditList {
    display: none;
  }

  .listDivEl a {
    width: 90%;
  }

  .listsPage .myListLeft,
  .singleListPage .myListLeft {
    width: 50%;
  }
  .itemMapDiv div {
    width: 95%;
  }
  .singleListPage {
    flex-wrap: wrap;
  }
  .singleListPage .myListLeft {
    width: fit-content;
    min-width: 70%;
    margin: auto;
  }
  .listHidden {
    display: none;
  }
  .listIcon {
    margin-top: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--color3);
  }
  .listIconSpan {
    width: fit-content;
    padding: 0.1em;
    background-color: var(--color1);
    border-radius: 5px;
    transition: 0.2s;
  }

  .listIconSpan svg {
    font-size: 1.3em;
    padding: 0.1em;
  }

  .listIcon span:hover {
    cursor: pointer;
    background-color: var(--color3);
    color: var(--color2);
    scale: 1.1;
  }
  .listsPage {
    flex-wrap: wrap;
    width: 100%;
  }
  .listsPage .myListLeft {
    width: 90%;
    margin: auto;
  }
  .listDivEl a {
    padding: 0.5em;
  }
  .listDivEl {
    width: 95%;
  }
  .listComponent {
    padding: 0.5em;
  }
  .listComponent div h2 {
    font-size: 18px;
  }
  .listComponent div p {
    font-size: 14px;
  }
}

@media (max-width: 460px) {
  .itemMapDiv div a {
    margin: 0.3em;
  }
  .itemDiv h2 {
    font-size: 14px;
  }
  .itemDiv h4 {
    font-size: 10px;
    font-weight: 100;
  }

  .item-form-label {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }

  .item-form-input {
    width: 100%;
  }
}

/* MODAL BASIC THING_______________________________________________________________________________ */

.modal-window,
.modal-delete-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.123s;
}

.modal-window > div {
  width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5em;
  border-radius: 5px;
  background: var(--color4);
  box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.5);
}

.cancelModal {
  position: fixed;
  left: -3%;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancelModal .insetBtnInverse {
  padding: 0.2em;
}

/* WHAT TO AD SECTION ___________________----------___----_--------------__-__----___-_--_____-__-_-__-- */

.whatToAdSection {
  width: fit-content;
  border-radius: 5px;
  margin: auto;
}

.whatToAdLink {
  border-radius: 5px;
  padding: 0.25em 0.8em;
  margin: auto;
  margin-top: 1em;
  transition: 0.3s;
  background-color: var(--color1);
  color: black;
  text-align: center;
  width: fit-content;
  border: 2px black solid;
}

.whatToAdLink:hover {
  box-shadow: inset 0 0 0 2.5em var(--color4);
  cursor: pointer;
}

.whatToAddList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.whatToAddList > a {
  width: 40%;
  padding: 2em;
}

.whatToAddBtn {
  border-radius: 5px;
  text-align: center;
  padding: 0.25em 0.8em;
  margin: 0.5em;
  height: 4em;
  transition: 0.25s;
  border: 1px solid var(--color3);
  color: var(--color5);
  background-color: var(--color3);
}

.whatToAddBtn:hover {
  border: 1px solid var(--color3);
  box-shadow: inset 0 0 0 3.5em var(--color1);
  color: var(--color3);
  cursor: pointer;
}

@media (max-width: 600px) {
  .whatToAdSection {
    width: 90%;
  }

  .whatToAdSection h1 {
    font-size: 20px;
  }
  .whatToAdSection h4 {
    font-size: 12px;
  }

.whatToAddList {
  width: 90%;
}

.whatToAddList > a {
  width: 100%;
}

}
